import React from "react";
import { NavLink } from "react-router-dom";

const PrimaryNav = ({ onToggleMegaMenu }) => {
  return (
    <div className="hidden 2xl:flex xl:flex lg:flex md:hidden items-center h-full space-x-1">
      <NavLink
        to="/studio"
        onMouseEnter={onToggleMegaMenu} 
        onMouseLeave={onToggleMegaMenu}
        className="pt-[20px] pb-[3px] mb-[13px] px-3 h-[50px] text-gray-700 hover:text-gray-900 border-b-2 border-transparent hover:border-[#B07641] transition-all flex items-center"
      >
        Designs
        <svg className="w-2.5 h-2.5 ml-2" aria-hidden="true" fill="none" viewBox="0 0 10 6">
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1l4 4 4-4" />
        </svg>
      </NavLink>

      <NavLink
        to="/trends"
        className="pt-[20px] mb-[13px] px-3 h-[50px] text-gray-700 hover:text-gray-900 border-b-2 border-transparent hover:border-[#B07641] transition-all"
      >
        Trends
      </NavLink>

      <NavLink
        to="/aboutus"
        className="pt-[20px] px-3 mb-[13px] h-[50px] text-gray-700 hover:text-gray-900 border-b-2 border-transparent hover:border-[#B07641] transition-all"
      >
        About Us
      </NavLink>
    </div>
  );
};

export default PrimaryNav;
