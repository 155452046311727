import React from "react";

function ChangePassword() {
    return (
        <div className="w-full lg:w-[55%] h-full p-6 md:p-8 shadow-xl rounded-xl border-t-2 border-gray-100 bg-white lg:mr-6">
            <h1 className="text-lg md:text-2xl font-bold mb-6">Change Password</h1>
            <div className="space-y-4">
                <div>
                    <label className="block text-gray-600 mb-2">Current Password</label>
                    <input
                        type="password"
                        className="w-full p-2 bg-gray-100 border rounded focus:outline-none"
                    />
                </div>
                <div>
                    <label className="block text-gray-600 mb-2">New Password</label>
                    <input
                        type="password"
                        className="w-full p-2 bg-gray-100 border rounded focus:outline-none"
                    />
                </div>
                <div>
                    <label className="block text-gray-600 mb-2">Confirm New Password</label>
                    <input
                        type="password"
                        className="w-full p-2 bg-gray-100 border rounded focus:outline-none"
                    />
                </div>
            </div>
            <div className="flex mt-6">
                <button className="px-4 md:px-6 py-2 border border-[#d8ab82] text-black rounded transition">
                    Cancel
                </button>
                <button className="ml-4 px-4 md:px-6 py-2 bg-[#d8ab82] text-white rounded hover:bg-[#ce8d50] transition">
                    Save Changes
                </button>
            </div>
        </div>
    );
}

export default ChangePassword;