import React, { useState, useEffect, useRef } from "react";
import Search from "./Search";
import CartDropdown from "./CartDropdown";
import { NavLink } from "react-router-dom";

const SecondaryNav = () => {
    const [isMegaMenuOpen, setIsMegaMenuOpen] = useState(false);
    const [isCartOpen, setIsCartOpen] = useState(false);
    const [cartItems] = useState([
        { id: 1, title: "Item Title", price: 20.00, imageUrl: "https://production-cdn1.patternbank.com/uploads/uploaded_files/attachments/004/473/513/1729726943/original/387553-preview-small.jpg?1729726943" },
        { id: 2, title: "Another Item", price: 20.00, imageUrl: "https://production-cdn1.patternbank.com/uploads/uploaded_files/attachments/004/473/513/1729726943/original/387553-preview-small.jpg?1729726943" },
        { id: 3, title: "Another Item", price: 20.00, imageUrl: "https://production-cdn1.patternbank.com/uploads/uploaded_files/attachments/004/473/513/1729726943/original/387553-preview-small.jpg?1729726943" },
        { id: 4, title: "Another Item", price: 20.00, imageUrl: "https://production-cdn1.patternbank.com/uploads/uploaded_files/attachments/004/473/513/1729726943/original/387553-preview-small.jpg?1729726943" },
    ]);

    const megaMenuRef = useRef(null);
    const buttonRef = useRef(null);
    const cartButtonRef = useRef(null);
    const cartDropdownRef = useRef(null);

    const toggleMegaMenu = () => {
        setIsMegaMenuOpen(!isMegaMenuOpen);
    };

    const toggleCartDropdown = () => {
        setIsCartOpen(!isCartOpen);
    };

    // Detect click outside the mega menu and cart dropdown
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                isMegaMenuOpen &&
                megaMenuRef.current &&
                !megaMenuRef.current.contains(event.target) &&
                buttonRef.current &&
                !buttonRef.current.contains(event.target)
            ) {
                setIsMegaMenuOpen(false);
            }

            if (
                isCartOpen &&
                cartDropdownRef.current &&
                !cartDropdownRef.current.contains(event.target) &&
                cartButtonRef.current &&
                !cartButtonRef.current.contains(event.target)
            ) {
                setIsCartOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isMegaMenuOpen, isCartOpen]);

    // Calculate total price
    const totalPrice = cartItems.reduce((total, item) => total + item.price, 0);

    return (
        <div className="w-full relative">
            <div className="flex items-end justify-end space-x-3">
                <button ref={buttonRef} className="flex hover:text-[#bc0033]" onClick={toggleMegaMenu}>
                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                    </svg>
                </button>

                <button className="hidden 2xl:flex xl:flex lg:flex md:flex hover:text-[#bc0033]">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                    </svg>
                </button>

                <button ref={cartButtonRef} className="flex items-center hover:text-[#bc0033] relative" onClick={toggleCartDropdown}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                    </svg>
                    <svg className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2" width="16" height="16">
                        <circle cx="8" cy="8" r="8" fill="red" />
                        <text x="8" y="11" textAnchor="middle" fontSize="10" fill="white" fontWeight="bold">{cartItems.length}</text>
                    </svg>
                </button>

                <NavLink to="/PersonalInfo" className="flex items-center group">
                    <svg className="h-7 w-7" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            className="group-hover:stroke-[#000000]"
                            opacity="0.4"
                            d="M12.1207 12.78C12.0507 12.77 11.9607 12.77 11.8807 12.78C10.1207 12.72 8.7207 11.28 8.7207 9.50998C8.7207 7.69998 10.1807 6.22998 12.0007 6.22998C13.8107 6.22998 15.2807 7.69998 15.2807 9.50998C15.2707 11.28 13.8807 12.72 12.1207 12.78Z"
                            stroke="#292D32"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            className="group-hover:stroke-[#0d0505]"
                            opacity="0.34"
                            d="M18.7398 19.3801C16.9598 21.0101 14.5998 22.0001 11.9998 22.0001C9.39977 22.0001 7.03977 21.0101 5.25977 19.3801C5.35977 18.4401 5.95977 17.5201 7.02977 16.8001C9.76977 14.9801 14.2498 14.9801 16.9698 16.8001C18.0398 17.5201 18.6398 18.4401 18.7398 19.3801Z"
                            stroke="#292D32"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            className="group-hover:stroke-[#bc0033]"
                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                            stroke="#292D32"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </NavLink>
            </div>

            <div
                ref={megaMenuRef}
                className={`absolute top-[40px] right-[-72px] xl:right-[-32px] lg:right-[-32px] mt-2 
                w-[100vw] max-w-[1529px] lg:max-w-[1513px] md:max-w-[768px] h-[300px] 
                bg-gray-100 shadow-lg p-4 z-50 flex justify-center 
                transition-all duration-300 ease-in-out transform ${isMegaMenuOpen ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-5 pointer-events-none'
                    }`}
            >
                <Search toggleMegaMenu={toggleMegaMenu} />
            </div>

            {isCartOpen && (
                <CartDropdown cartItems={cartItems} totalPrice={totalPrice} cartDropdownRef={cartDropdownRef} />
            )}
        </div>
    );
};

export default SecondaryNav;
