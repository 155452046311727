import React, { useState } from "react";

function Sidebar({ onItemClick }) {
  // State to track the active button
  const [activeButton, setActiveButton] = useState("personalInfo");

  // Function to handle button click
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    onItemClick(buttonName);
  };

  return (
    <div className="w-full lg:w-[22%] bg-white p-6 pt-10 pb-[3rem] shadow-xl rounded-xl border-t-2 border-gray-100 mb-8 lg:mb-0 lg:mx-6">
      <div className="flex flex-col items-center">
        <img
          className="w-24 h-24 rounded-full object-cover"
          src="https://via.placeholder.com/150"
          alt="Profile"
        />
        <h2 className="mt-4 text-lg md:text-xl font-semibold">Roland Donald</h2>
        <p className="text-gray-500">Designer</p>
      </div>
      <div className="mt-10">
        <button
          className={`flex items-center w-full py-2 px-4 text-black font-semibold rounded-xl ${
            activeButton === "personalInfo" ? "bg-orange-100" : "hover:bg-orange-100"
          }`}
          onClick={() => handleButtonClick("personalInfo")}
        >
          <svg
            fill="#B07641"
            className="w-6 h-6 mr-4"
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M16 15.503A5.041 5.041 0 1 0 16 5.42a5.041 5.041 0 0 0 0 10.083zm0 2.215c-6.703 0-11 3.699-11 5.5v3.363h22v-3.363c0-2.178-4.068-5.5-11-5.5z" />
          </svg>
          Personal Information
        </button>
        <button
          className={`flex items-center w-full py-2 px-4 mt-4 text-gray-600 font-semibold rounded-xl ${
            activeButton === "changePassword" ? "bg-orange-100" : "hover:bg-orange-100"
          }`}
          onClick={() => handleButtonClick("changePassword")}
        >
          <svg
            className="w-6 h-6 mr-4"
            viewBox="0 0 24 24"
            fill="#B07641"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.25 10.0546V8C5.25 4.27208 8.27208 1.25 12 1.25C15.7279 1.25 18.75 4.27208 18.75 8V10.0546C19.8648 10.1379 20.5907 10.348 21.1213 10.8787C22 11.7574 22 13.1716 22 16C22 18.8284 22 20.2426 21.1213 21.1213C20.2426 22 18.8284 22 16 22H8C5.17157 22 3.75736 22 2.87868 21.1213C2 20.2426 2 18.8284 2 16C2 13.1716 2 11.7574 2.87868 10.8787C3.40931 10.348 4.13525 10.1379 5.25 10.0546ZM6.75 8C6.75 5.10051 9.10051 2.75 12 2.75C14.8995 2.75 17.25 5.10051 17.25 8V10.0036C16.867 10 16.4515 10 16 10H8C7.54849 10 7.13301 10 6.75 10.0036V8ZM8 17C8.55228 17 9 16.5523 9 16C9 15.4477 8.55228 15 8 15C7.44772 15 7 15.4477 7 16C7 16.5523 7.44772 17 8 17ZM12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17ZM17 16C17 16.5523 16.5523 17 16 17C15.4477 17 15 16.5523 15 16C15 15.4477 15.4477 15 16 15C16.5523 15 17 15.4477 17 16Z"
            />
          </svg>
          Change Password
        </button>
      </div>
    </div>
  );
}

export default Sidebar;
