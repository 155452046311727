import React from "react";
import Logo from "../Logo";
import PrimaryNav from "./PrimaryNav";
import SecondaryNav from "./SecondaryNav";
import MobileMenu from "./Mobile/MobileMenu";
import MegaMenu from "./MegaMenu";
import useToggle from "../../../Hooks/useToggle";

const Navbar = () => {
    const [menuOpen, toggleMenu] = useToggle();
    const [megaMenuOpen, setMegaMenuOpen] = React.useState(false);

    const handleMouseEnter = () => {
        setMegaMenuOpen(true);
    };

    const handleMouseLeave = () => {
        setMegaMenuOpen(false);
    };

    return (
        <nav className="sticky top-0 bg-white font-sans border-b-2 border-transparent shadow-xl border-gray-200 z-50">
            <div className="max-w-full px-8 flex justify-between items-center">
                <Logo width={110} height={80} />
                <PrimaryNav
                    onToggleMegaMenu={handleMouseEnter}
                    isMegaMenuOpen={megaMenuOpen}
                />
                <div className="hidden lg:flex xl:flex items-center">
                    <SecondaryNav />
                </div>
                {/* Mobile Button for md and below */}
                <div className="flex sm:flex md:flex lg:hidden xl:hidden items-center">
                    <SecondaryNav />
                    <button onClick={toggleMenu}>
                        <svg className="w-6 h-6 ml-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                    </button>
                </div>
            </div>
            {/* Mega Menu */}
            <div onMouseLeave={handleMouseLeave}>
                <MegaMenu isOpen={megaMenuOpen} />
            </div>
            {/* Mobile Menu with Overlay */}
            <MobileMenu isOpen={menuOpen} onClose={toggleMenu} />
        </nav>
    );
};

export default Navbar;
