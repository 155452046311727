import React, { useState } from "react";
import Sidebar from "./Sidebar";
import MainContent from "./MainContent";
import HeaderSection from "./HeaderSection";
import ChangePassword from "./ChangePassword";

function ProfilePage() {
    const [activeComponent, setActiveComponent] = useState("personalInfo");

    const handleSidebarClick = (component) => {
        setActiveComponent(component);
    };

    return (
        <div className="relative overflow-hidden">
            <HeaderSection />
            {/* Person Info Section */}
            <div className="relative mt-[5rem] mb-8 bg-transparent w-full flex flex-col lg:flex-row items-center lg:items-start justify-center z-30 px-4 md:px-8">
                {/* Sidebar */}
                <Sidebar onItemClick={handleSidebarClick} />
                {/* Main Content */}
                {activeComponent === "personalInfo" ? <MainContent /> : <ChangePassword />}
            </div>
        </div>
    );
}

export default ProfilePage;
