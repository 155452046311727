import React from "react";
import Ellipse1 from "../../../Assets/Images/Ellipse 39.png";

function HeaderSection() {
    return (
        <section className="absolute w-full h-[230px] bg-[#d99352] z-0 top-0">
            <img
                src={Ellipse1}
                alt=""
                className="absolute bottom-[72px] right-[-87px] w-[10rem] h-[10rem] md:w-[15rem] md:h-[15rem] object-cover"
            />
            <img
                src={Ellipse1}
                alt=""
                className="absolute bottom-[140px] right-[57px] w-[10rem] h-[10rem] md:w-[15rem] md:h-[15rem] object-cover"
            />
        </section>
    );
}

export default HeaderSection;