import React from "react";
import Logo from "../Logo";
import MobileFooter from "./MobileFooter";
import DesktopFooter from "./DesktopFooter";

const Footer = () => {

    return (
        <div className="slick bottom-0 px-4 pt-16 mx-0 rounded-tl-lg rounded-tr-lg xl:mx-[90px] lg:mx-[90px] md:mx-0 md:px-24 lg:px-8">
            {/* Mobile Design */}
            <MobileFooter />
            {/* Desktop Design */}
            <DesktopFooter />
            <div className="flex flex-col-reverse items-center justify-between border-t  lg:flex-row">
                <p className="text-sm text-gray-600 text-center xl:text-start lg:text-start md:text-start">
                    © Copyright 2025 K&K Group Developers Inc. All rights reserved.
                </p>
                <Logo />
            </div>
        </div>
    );
};

export default Footer;
