import React from 'react';
import PersonInfo from '../Components/Opal/PersonInformation/MainPersonalInfo';

const PersonInformation = () => {
  return (
    <PersonInfo />
  );
};

export default PersonInformation;
